function findGetParameter(parameterName) {
  var result = null,
    tmp = [];
  location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

function getConfiguration() {
    const configuration = {};  
    const configurationQuery = findGetParameter("configuration");
    if (configurationQuery) {
        configurationQuery.split(";").forEach((param) => {
            const [parameter, value] = param.split(/:(.+)/);
            if (parameter != null && value != null) {
                configuration[parameter] = decodeURI(value);
            }
        })
    }
    return configuration;
}

const realityViewer = document.getElementById("reality-viewer");
const projectId = findGetParameter("project") || "test-id";
realityViewer.setAttribute("project-id", projectId);
realityViewer.addEventListener("loaded", () => {
    const configuration = getConfiguration();
    Object.keys(configuration).forEach((parameter) => {
        realityViewer.updateConfiguration(parameter, configuration[parameter]);
    })

    const action = findGetParameter("action");
    if (action === "ar") {
      setTimeout(() => {
        realityViewer.triggerAR();
      }, 1000)
    }
});

const gui = findGetParameter("gui");
if (gui === "true") {
    realityViewer.setAttribute("gui", "true");
}

const stats = findGetParameter("stats");
if (stats === "true") {
    realityViewer.setAttribute("stats", "true");
}
